<div class="app-shell-menu flex flex-column h-screen max-w-22rem bg-blue-50">

  <div class="flex-0 p-4 pb-4 border border-bottom-1 border-gray-200 sticky">
      <div
        (click)="onTitleClick()"
        class="text-xl flex cursor-pointer justify-content-start"
      >
        <a class="text-primary font-bold">NITRO</a>
        <a class="text-plain font-semibold">Admin</a>
      </div>

    <div class="logged-user text-gray-600 text-md mt-2" *ngIf="loggedUser">
        <span>{{loggedUser.name}}</span>
    </div>
  </div>

  <div class="menu flex-1 mt-20  h-full overflow-y-auto overflow-x-hidden" *ngIf="menu$ | async" style="position: relative;">
    <div class="block main-block menu-1 w-full my-3"
         *ngFor="let mainItem of (menu$ | async)">

      <header class="main-header flex align-items-center justify-content-between py-2">
        <nit-button-link [routerLink]="mainItem.routerLink"
                         [label]="mainItem.label"
                         [icon]="mainItem.icon"
                         class="flex-1"
                         buttonStyleClass="w-full p-button-text p-button-plain text-left"
        ></nit-button-link>
        <span *ngIf="mainItem.badge" class="badge flex-0">
          {{ mainItem.badge }}
        </span>
      </header>

      <div *ngFor="let mainItem of mainItem.items">
        <!-- Expandable items -->
        <div *ngIf="mainItem.label === 'Transactions' || mainItem.label === 'Documents'"
             (click)="mainItem.expanded = !mainItem.expanded"
             class="main-item-toggle">
          <nit-button-link
            [routerLink]="mainItem.routerLink"
            [label]="mainItem.label"
            [icon]="'pi pi-chevron-down'"
            class="flex-1"
            buttonStyleClass="w-full p-button-text p-button-plain text-left flex justify-between items-center"
          ></nit-button-link>
          <span *ngIf="mainItem.badge" class="badge flex-0">{{ mainItem.badge }}</span>
        </div>

        <!-- Non-expandable items -->
        <div *ngIf="mainItem.label !== 'Transactions' && mainItem.label !== 'Documents'"
             class="main-item">
          <nit-button-link
            [routerLink]="mainItem.routerLink"
            [label]="mainItem.label"
            [icon]="mainItem.icon"
            class="flex-1"
            buttonStyleClass="w-full p-button-text p-button-plain text-left"
          ></nit-button-link>
<!--          <span *ngIf="mainItem.badge" class="badge flex-0">{{ mainItem.badge }}</span>-->
        </div>

        <!-- Sub-items for expandable items -->
        <div *ngIf="mainItem.expanded">
          <div *ngFor="let subItem of mainItem.items">
            <nit-button-link [routerLink]="subItem.routerLink"
                             [label]="subItem.label"
                             [icon]="subItem.icon"
                             class="flex-1 ml-4"
                             buttonStyleClass="w-full p-button-text p-button-plain p-button-sm text-left"
            ></nit-button-link>
<!--            <span *ngIf="subItem.badge" class="badge flex-0">{{ subItem.badge }}</span>-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer class="border border-top-1 border-gray-200 py-2">
    <button type="button" pButton
            class="p-button-text p-button-danger w-full text-left"
            icon="pi pi-sign-out"
            (click)="onLogoutClick()"
            label="Se déconnecter"
    ></button>
    <span class="version text-sm text-gray-500 pl-4">
    Version {{ version }}
  </span>
  </footer>


</div>
