import {Component, OnInit} from '@angular/core';
import {Message, MessageService} from "primeng/api";
import {Observable, of} from "rxjs";
import {AppShellRoutingProvider, CustomerContextProvider, CustomerDocumentContextProvider, CustomerTransactionContextProvider, FinancialAccountContextProvider, NitroMessageService, NitroRuleActionContextProvider, NitroRuleContextProvider, ThirdpartyContextProvider, ThirdpartyEntityContextProvider, TrusteeContextProvider} from "@fiscalteam/ngx-nitro-services";
import {ShellMenuService} from "../shell-menu.service";
import {AppShellModalHelpers} from "./app-shell-modal-helpers";
import {AdminFrontContextService} from "../admin-front-context.service";

@Component({
  selector: 'adm-app-shell',
  templateUrl: './app-shell.component.html',
  styleUrls: ['./app-shell.component.scss'],
  providers: [
    ShellMenuService,
    AppShellRoutingProvider,
    MessageService,
    //
    TrusteeContextProvider,
    CustomerContextProvider,
    NitroRuleContextProvider,
    NitroRuleActionContextProvider,
    ThirdpartyContextProvider,
    ThirdpartyEntityContextProvider,
    CustomerDocumentContextProvider,
    FinancialAccountContextProvider,
    CustomerTransactionContextProvider,
    AdminFrontContextService,
  ]
})
export class AppShellComponent implements OnInit {

  messages$: Observable<Message[]> = of([]);

  ModalKeys = AppShellModalHelpers;
  menuCollapsed: boolean = false;

  constructor(
    private messageService: NitroMessageService,
    private primengMessageService: MessageService,
  ) {
  }

  ngOnInit(): void {
    this.messages$ = this.messageService.getMessages$();
  }

  discardMessages() {
    this.messageService.discardAll();
  }

  onMessageChange(value: Message[]) {
    this.messageService.setMessages(value);
  }


  onOptimsticLockModalClose() {
    this.primengMessageService.clear(AppShellModalHelpers.OPTIMISTIC_LOCK_MODAL_KEY);
  }

  onOptimsticLockRefetch(message: any) {
    AppShellModalHelpers.callOptimisticLockReloadCallback(message);
    this.primengMessageService.clear(AppShellModalHelpers.OPTIMISTIC_LOCK_MODAL_KEY);
  }

}
