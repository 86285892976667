<div class="shell-page flex flex-column h-full overflow-hidden">

  <ng-container *ngIf="hasNestedShells$ | async; else lastShell">
    <header *ngIf="!(isNestedShells$ | async)" class="flex-grow-0  breadcrumb-header">
      <p-breadcrumb [model]="(breadcrumbMenuModel$ | async) || []"
      ></p-breadcrumb>
    </header>
  </ng-container>

  <ng-template #lastShell>
    <header class="flex-grow-0 flex align-items-center">
      <ng-template [ngIf]="titleTemplateRef && (!hideTitleIfNested || (isNestedShells$ | async) == false)">
        <h1 class="mx-2 w-20rem overflow-hidden text-overflow-ellipsis white-space-nowrap"
            [style]="{flex: '0 0 20rem'}"
        >
          <ng-container [ngTemplateOutletContext]="{}"
                        [ngTemplateOutlet]="titleTemplateRef"
          ></ng-container>
        </h1>
      </ng-template>

      <ng-template [ngIf]="toolbarLeftTemplateRef">
        <div class="mx-2 overflow-hidden flex align-items-center relative"
             [style]="{flex: '0 1 auto'}"
        >
          <ng-container [ngTemplateOutlet]="toolbarLeftTemplateRef"></ng-container>
        </div>
      </ng-template>

      <div class="spacer flex-1"></div>

      <ng-template [ngIf]="toolbarRightTemplateRef">
        <div class="px-2 overflow-hidden flex align-items-center relative"
             [style]="{flex: '0 1 auto'}"
        >
          <ng-container [ngTemplateOutlet]="toolbarRightTemplateRef"></ng-container>
        </div>
      </ng-template>

    </header>
  </ng-template>

  <div class="content flex-1 relative overflow-auto">
    <ng-content></ng-content>
  </div>

</div>
